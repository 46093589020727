import React, { Component } from "react";
import { connect } from "react-redux";
import Notifications from "react-notification-system-redux";

class NotificationWrapper extends Component {
  render() {
    //Optional styling
    const style = {
      NotificationItem: {
        // Override the notification item
        DefaultStyle: {
          // Applied to every notification, regardless of the notification level
          margin: "10px 5px",
        },

        success: {
          // Applied only to the success notification item
        },
      },
      Dismiss: {
        DefaultStyle: {
          right: "unset",
          left: "5px",
        },
      },
    };
    return (
      <Notifications notifications={this.props.notifications} style={style} />
    );
  }
}

const mapStateToProps = (state) => ({
  notifications: state.notifications,
});
const mapDispatchToProps = (dispatch) => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NotificationWrapper);
