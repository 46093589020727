import * as ownerMethods from "../async/ownerMethods";
import { setTransactionResults } from "./blockchainActions";
import { handleError } from "./errorActions";
import { error as errorNotif } from "react-notification-system-redux";

export const transferOwnership = (newOwner, blockchainData) => {
  return async (dispatch) => {
    try {
      const res = await ownerMethods.transferOwnership(
        newOwner,
        blockchainData
      );
      console.log("transferResults", res);
      const { newOwner: owner, previousOwner } =
        res.events.OwnershipTransferred.returnValues;
      const result = {
        message: "نوع تراکنش: انتقال مالکیت درگاه.",
        params: [
          {
            label: "صاحب قبلی",
            value: previousOwner,
          },
          {
            label: "صاحب جدید",
            value: owner,
          },
        ],
        transactionHash: res.transactionHash,
      };
      dispatch(setTransactionResults(result));
      return res;
    } catch (err) {
      const errorMessage = handleError(err);
      if (errorMessage) {
        dispatch(errorNotif(errorMessage));
      }
      throw err;
    }
  };
};
export const changeWallet = (address, blockchainData) => {
  return async (dispatch) => {
    try {
      const res = await ownerMethods.changeWallet(address, blockchainData);
      console.log(res);
      const { newWallet, previousWallet } =
        res.events.WalletChanged.returnValues;
      const result = {
        message: "نوع تراکنش: تغییر کیف پول متصل به درگاه.",
        params: [
          {
            label: "کیف پول قبلی",
            value: previousWallet,
          },
          {
            label: "کیف پول جدید",
            value: newWallet,
          },
        ],
        transactionHash: res.transactionHash,
      };
      dispatch(setTransactionResults(result));
      return res;
    } catch (err) {
      const errorMessage = handleError(err);
      if (errorMessage) {
        dispatch(errorNotif(errorMessage));
      }
      throw err;
    }
  };
};

export const withdraw = (weiAmount, selectedCurrency, blockchainData) => {
  return async (dispatch) => {
    try {
      const res = await ownerMethods.withdraw(
        weiAmount,
        selectedCurrency,
        blockchainData
      );
      const { sender, receiver, value } = res.events.TokenTransferred
        ? res.events.TokenTransferred.returnValues
        : res.events.CoinTransferred.returnValues;
      const result = {
        message: "نوع تراکنش: برداشت از درگاه.",
        params: [
          {
            label: "فرستنده",
            value: sender,
          },
          {
            label: "دریافت کننده",
            value: receiver,
          },
          {
            label: "نوع ارز",
            value: selectedCurrency.label,
          },
          {
            label: "مبلغ",
            value: selectedCurrency.fromWeiFunction(value, 6),
          },
        ],
        transactionHash: res.transactionHash,
      };
      dispatch(setTransactionResults(result));
      return res;
    } catch (err) {
      const errorMessage = handleError(err);
      if (errorMessage) {
        dispatch(errorNotif(errorMessage));
      }
      throw err;
    }
  };
};

export const transferTo = (
  recipient,
  weiAmount,
  selectedCurrency,
  blockchainData
) => {
  return async (dispatch) => {
    try {
      const res = await ownerMethods.transferTo(
        recipient,
        weiAmount,
        selectedCurrency,
        blockchainData
      );
      const events = res.events.TokenTransferred
        ? res.events.TokenTransferred
        : res.events.CoinTransferred;
      console.log(events);
      const { sender, receiver, value } = events.returnValues;
      const result = {
        message: "نوع تراکنش: انتقال از درگاه به کیف پول.",
        params: [
          {
            label: "فرستنده",
            value: sender,
          },
          {
            label: "دریافت کننده",
            value: receiver,
          },
          {
            label: "نوع ارز",
            value: selectedCurrency.label,
          },
          {
            label: "مبلغ",
            value: selectedCurrency.fromWeiFunction(value, 6),
          },
        ],
        transactionHash: res.transactionHash,
      };
      dispatch(setTransactionResults(result));
      return res;
    } catch (err) {
      const errorMessage = handleError(err);
      if (errorMessage) {
        dispatch(errorNotif(errorMessage));
      }
      throw err;
    }
  };
};
