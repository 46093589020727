import { transactionList, setEvents } from "../../components/utility/events";

export const transferOwnership = (newOwner, blockchainData) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { blockpay, account } = blockchainData;
      blockpay.methods
        .transferOwnership(newOwner)
        .send({ from: account })
        .on("receipt", (receipt) => {
          //console.log("events of transfer ownership", receipt.events);
          resolve(receipt);
        })
        .on("error", (error) => {
          reject(error); // object error
        });
      setEvents(blockpay, transactionList.transferOwnwerhip);
    } catch (error) {
      reject(error);
    }
  });
};

export const changeWallet = (address, blockchainData) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { blockpay, account } = blockchainData;
      blockpay.methods
        .changeWallet(address)
        .send({ from: account })
        .on("receipt", (receipt) => {
          resolve(receipt);
          // console.log(this.state.web3);
        })
        .on("error", (error, receipt) => {
          reject(error, receipt); // object error
        });
      setEvents(blockpay, transactionList.changeWallet);
    } catch (error) {
      reject(error);
    }
  });
};

export const withdraw = (weiAmount, selectedCurrency, blockchainData) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { blockpay, account } = blockchainData;
      const { tokenAddress, isToken } = selectedCurrency;

      if (isToken) {
        blockpay.methods
          .withdrawToken(weiAmount, tokenAddress)
          .send({ from: account })
          .on("receipt", (receipt) => {
            resolve(receipt);
          })
          .on("error", (error, receipt) => {
            reject(error, receipt); // object error
          });
        setEvents(blockpay, transactionList.transferToken);
      } else {
        blockpay.methods
          .withdrawEther(weiAmount)
          .send({ from: account })
          .on("receipt", (receipt) => {
            resolve(receipt);
          })
          .on("error", (error, receipt) => {
            reject(error, receipt); // object error
          });
        setEvents(blockpay, transactionList.transferCoin);
      }
    } catch (error) {
      reject(error);
    }
  });
};

export const transferTo = (recipient, weiAmount, selectedCurrency, blockchainData) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { tokenAddress, isToken } = selectedCurrency;
      const { blockpay, account } = blockchainData;
      //console.log(recipient, weiAmount, tokenAddress);
      if (isToken) {
        blockpay.methods
          .transferTokenTo(recipient, weiAmount, tokenAddress)
          .send({ from: account })
          .on("receipt", (receipt) => {
            resolve(receipt);
          })
          .on("error", (error, receipt) => {
            reject(error, receipt);
          });
        setEvents(blockpay, transactionList.transferToken);
      } else {
        blockpay.methods
          .transferEtherTo(recipient, weiAmount)
          .send({ from: account })
          .on("receipt", (receipt) => {
            resolve(receipt);
          })
          .on("error", (error, receipt) => {
            reject(error, receipt);
          });
        setEvents(blockpay, transactionList.transferCoin);
      }
    } catch (error) {
      reject(error);
    }
  });
};
