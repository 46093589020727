import React from "react";
import PropTypes from "prop-types";

import background from "../../assets/images/Login.svg";
import PageTitle from "./PageTitle";

function ModalOverlay({ children, className, title }) {
  if (title && title.length > 0) {
    return (
      <div style={{ backgroundImage: `url(${background})` }} className="modal">
        <div className="modal-title">
          <PageTitle />
        </div>
        <h3 style={{ width: "100%", textAlign: "center" }}>{title}</h3>

        <div className={`modal-main${className && className.length > 0 ? " " + className : ""}`}>
          {children}
        </div>
      </div>
    );
  }
  return (
    <div style={{ backgroundImage: `url(${background})` }} className="modal">
      <div className={`modal-main${className ? ` ${className}` : ""}`}>
        <div className="modal-title">
          <PageTitle />
        </div>
        {children}
      </div>
    </div>
  );
}

ModalOverlay.propTypes = {
  className: PropTypes.string,
  children: PropTypes.element.isRequired,
  title: PropTypes.string,
};
export default ModalOverlay;
