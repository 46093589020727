import React from "react";
import ReactDOM from "react-dom";
import App from "./components/App";
// setup redux
import { Provider } from "react-redux";
import configureStore from "./store/configureStore";
import "./fonts.css";
import NotificationWrapper from "./components/partials/NotificationWrapper";

export const { store } = configureStore();

ReactDOM.render(
  <Provider store={store}>
    <NotificationWrapper />
    <App />
  </Provider>,
  document.getElementById("root")
);
