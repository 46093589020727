import React from "react";
import PropTypes from "prop-types";

function ValueDisplay(props) {
  const { name, label, id, className, display, icon } = props;

  return (
    <div
      id={`${name + "-" + id}`}
      className={`form-group${className ? ` ${className}` : ""}`}
    >
      <label className="input-label" htmlFor={name}>
        {label}
      </label>
      <div className="input-wrapper">
        <div className="text-input">
          {display}
          {icon ? (
            <img
              src={icon}
              className="merchang-logo input-logo"
              alt="لوگوی فروشگاه"
            ></img>
          ) : (
            ""
          )}
        </div>
      </div>
    </div>
  );
}

ValueDisplay.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string.isRequired,
  id: PropTypes.string,
  className: PropTypes.string,
  display: PropTypes.string,
  icon: PropTypes.string,
};

export default ValueDisplay;
