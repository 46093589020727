import React, { useReducer, useState } from "react";
import PropTypes from "prop-types";
import { icons } from "../../utility/icons";

// handle

const DropdownSelect = (props) => {
  const { label, name, id, parentClass, options, onChangeAction } = props;
  const [isOpen, setIsOpen] = useState(false);
  const [selectedValue, handleSelect] = useReducer((_current, option) => {
    setIsOpen(false);
    onChangeAction && onChangeAction(option);
    return option;
  }, options[0]);

  return (
    <div
      id={`${name + "-" + id}`}
      className={`form-group${parentClass ? ` ${parentClass}` : ""}`}
    >
      <label className="input-label" htmlFor={name}>
        {label}
      </label>
      <div className="input-wrapper">
        <div className="select-box" onClick={() => setIsOpen(!isOpen)}>
          <i className="option-logo">
            {selectedValue.icon
              ? selectedValue.icon
              : icons[`${selectedValue.name}_circ_active`]}
          </i>
          <p className="option-label">{selectedValue.label}</p>
        </div>
        <span
          className={`icon-arrow select-arrow ${isOpen ? "open" : "close"}`}
        ></span>
      </div>
      <ul className={`dropdown-wrapper ${isOpen ? "show" : "hide"}`}>
        {options.map((option, key) => (
          <li
            key={`option-${key}`}
            className={`dropdown-option${
              option.value === selectedValue ? " selected" : ""
            }`}
            onClick={() => handleSelect(option)}
          >
            {
              <i className="option-logo">
                {option.icon
                  ? option.icon
                  : icons[`${option.name}_circ_active`]}
              </i>
            }
            <p className="option-label">{option.label}</p>
          </li>
        ))}
      </ul>
    </div>
  );
};

DropdownSelect.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  id: PropTypes.string,
  parentClass: PropTypes.string,
  selectClass: PropTypes.string,
  onChangeAction: PropTypes.func,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      label: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      className: PropTypes.string,
      icon: PropTypes.any,
    }),
  ),
};

DropdownSelect.defaultProps = {};

export default DropdownSelect;
