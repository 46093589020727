import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

import { icons } from "../../utility/icons";

function ValueInput(props) {
  const {
    name,
    label,
    id,
    type,
    icon,
    className,
    onChangeAction,
    balance,
    weiBalance,
    toWei,
    error,
    touched,
    defaultVal,
  } = props;
  const [value, setValue] = useState("");
  const [weiValue, setWeiValue] = useState("0");
  const [isActive, setIsActive] = useState(false);

  const handleChange = (ev) => {
    let value = ev.target.value;
    setValue(value);
    if (value === "") value = "0";
    setWeiValue(toWei(value));
  };

  useEffect(() => {
    setIsActive(weiValue !== "0");
    onChangeAction && onChangeAction(weiValue);
  }, [onChangeAction, weiValue]);

  const setMaxValue = () => {
    if (balance > 0) {
      setValue(balance);
      setWeiValue(weiBalance);
    }
  };

  useEffect(() => {
    setValue("");
    setWeiValue("0");
    onChangeAction("0");
  }, [weiBalance, onChangeAction]);

  useEffect(() => {
    if (defaultVal > 0) {
      setValue(defaultVal);
      setWeiValue(toWei(defaultVal.toString()));
      onChangeAction(defaultVal.toString());
    } else {
      setValue("");
      setWeiValue("0");
      onChangeAction("0");
    }
  }, [defaultVal, onChangeAction]);

  return (
    <div
      id={`${name + "-" + id}`}
      className={`form-group${className ? ` ${className}` : ""}`}
    >
      <label className="input-label" htmlFor={name}>
        {label}
      </label>
      <div className="input-wrapper">
        {defaultVal > 0 ? (
          <div className="text-input" readOnly>
            {defaultVal}
          </div>
        ) : (
          <input
            maxLength={18}
            className="text-input"
            placeholder="0"
            onChange={handleChange}
            value={value}
            type={type}
            step="0.0001"
            min={0}
          />
        )}

        {icon ? (
          <i className="input-logo">
            {icons[`${icon}_rect_${isActive ? "active" : "inactive"}`]}
          </i>
        ) : (
          <button
            type="button"
            onClick={setMaxValue}
            disabled={balance === 0}
            className="input-button"
          >
            MAX
          </button>
        )}
      </div>
      {error && touched && <p className="error">{error}</p>}
    </div>
  );
}

ValueInput.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  type: PropTypes.string,
  id: PropTypes.string,
  className: PropTypes.string,
  icon: PropTypes.any,
  onChangeAction: PropTypes.func,
  balance: PropTypes.number,
  defaultVal: PropTypes.number,
  weiBalance: PropTypes.string,
  toWei: PropTypes.func,
  showIcon: PropTypes.bool,
};

ValueInput.defaultProps = {
  type: "text",
  showIcon: false,
};

export default ValueInput;
