import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";

import ModalOverlay from "./ModalOverlay";

import successGif from "../../assets/logo/success.gif";

function TransactionSuccess(props) {
  const [canProceed, setCanProcees] = useState(true);
  const { resetHandler, children, results, isOwner } = props;
  const transactionHistory = useSelector(
    (state) => state.success?.transactionHistory[results.transactionHash],
  );

  useEffect(() => {
    const requiredConfirmations = props.results.requiredConfirmations;
    const current = transactionHistory?.number;
    if (!requiredConfirmations || current >= requiredConfirmations) {
      setCanProcees(true);
    } else setCanProcees(false);
  }, [transactionHistory, props.results.requiredConfirmations]);
  return (
    <ModalOverlay className="transaction-success-page">
      <img className="success-banner" src={successGif} alt="transaction results" />
      <h2 className="transaction-result success">تراکنش شما با موفقیت انجام شد.</h2>

      {children}
      <h3 className="transaction-type">{results.message}</h3>
      <ul className="notification-data-wrapper">
        {results.params.map(({ label, value }, idx) => (
          <li key={idx} className="notification-data-item">
            <label htmlFor={`item-${idx}`} className="item-label">
              {label}:
            </label>
            <p id={`item-${idx}`} className="item-value ltr">
              {value}
            </p>
          </li>
        ))}
        <li key="link_to_etherscan" className="notification-data-item" style={{ display: "none" }}>
          <label htmlFor="link-to-etherscan" className="item-label">
            مشاهده در اتراسکن:
          </label>
          <a
            className="item-value ltr"
            rel="noreferrer"
            target="_blank"
            href={`https://rinkeby.etherscan.io/tx/${results.transactionHash}`}
          >
            <p className="item-value ltr">{results.transactionHash}</p>
          </a>
        </li>
      </ul>
      <button
        className={`button return${!canProceed ? " disable" : ""}`}
        onClick={() => {
          resetHandler(results.transactionHash, results.action === "testToken");
        }}
        disabled={!canProceed}
      >
        {!canProceed
          ? `نیازمند تایید شبکه، ${transactionHistory.number || 0} از 5.`
          : isOwner
          ? "بازگشت"
          : results.action === "testToken"
          ? "ادامه"
          : "بازگشت به وب‌سایت پذیرنده"}
      </button>
    </ModalOverlay>
  );
}

TransactionSuccess.prototypes = {
  resetHandler: PropTypes.func.isRequired,
};

export default TransactionSuccess;
