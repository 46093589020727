import React from "react";

import background from "../../assets/svg/background-overlay.svg";
import LoadingLogo from "../../assets/logo/animation_500_ko2wjk7r.gif";

export default function Loader(props) {
  return (
    <div className="modal-content" style={{ backgroundImage: { background } }}>
      <img alt="Loading..." className="loading-logo" src={LoadingLogo} />
      <h2 className="loading-content">در حال اتصال به درگاه</h2>
    </div>
  );
}
