import React from "react";
import logo from "../../assets/logo/loading-logo.png";

function PageTitle(props) {
  return (
    <>
      <a
        className="navbar-brand"
        href="https://blocksc.ir"
        target="_blank"
        rel="noopener noreferrer"
      >
        <img src={logo} className="main-logo" alt="main logo" />
        <span>&nbsp;درگاه پرداخت هوشمند بلاک‌پی</span>
      </a>
    </>
  );
}

export default PageTitle;
