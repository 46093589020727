import * as actionTypes from "../actionTypes";

const defaultState = {
  transactionHistory: {},
};

export const successReducer = (state = defaultState, actions) => {
  switch (actions.type) {
    case actionTypes.SET_TRANSACTION_HISTORY_DATA:
      return {
        ...state,
        transactionHistory: { ...state.transactionHistory, ...actions.payload },
      };
    default:
      return state;
  }
};
