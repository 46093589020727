import Web3 from "web3";
import WalletConnectProvider from "@walletconnect/web3-provider";
import BlockPay from "../../abis/BlockPayContract.json";
import ERC20 from "../../abis/IERC20.json";

export const loadWeb3Metamask = () => {
  return new Promise(async (resolve, reject) => {
    try {
      window.web3 = new Web3(window.ethereum);
      await window.ethereum.enable();
      resolve(window.web3);
    } catch (error) {
      console.log(error);
      reject("Non-Ethereum browser detected. You should consider trying A web3 wallet!");
    }
  });
};

export const loadWeb3WalletConnect = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const provider = new WalletConnectProvider({
        rpc: {
          1996: "https://rpc.blocksc.ir", // required
        },
      });
      await provider.enable();
      const web3 = await new Web3(provider);
      resolve(web3);
    } catch (error) {
      console.log(error);
      reject("Non-Ethereum browser detected. You should consider trying A web3 wallet!");
    }
  });
};

export const loadWalletData = (web3) => {
  return new Promise(async (resolve, reject) => {
    try {
      web3.eth.handleRevert = true;
      // IMPORTANT! maximum number of subscribed confirmation events//
      web3.eth.transactionConfirmationBlocks = 10;
      const accounts = await web3.eth.getAccounts();
      const networkId = await web3.eth.net.getId();

      // Load Payment GateWay
      const blockpayData = BlockPay.networks[networkId];

      if (blockpayData) {
        try {
          var blockpay = new web3.eth.Contract(BlockPay.abi, blockpayData.address);
          var owner = await blockpay.methods.owner().call({ from: accounts[0] });
          var wallet = await blockpay.methods.wallet().call({ from: accounts[0] });

          var etherBalance = await web3.eth.getBalance(blockpayData.address);
        } catch (error) {
          throw error;
        }

        resolve({
          account: accounts[0],
          owner,
          wallet,
          etherBalance,
          blockpay,
        });
      } else {
        throw new Error("blockpay contract not deployed to detected network.");
      }
    } catch (error) {
      console.log(error);
      reject(error);
    }
  });
};

export const loadERC20Tokens = (web3, gatewayWallets, blockchainData) => {
  return new Promise(async (resolve, reject) => {
    try {
      // Load ERC20 tokens
      const {
        blockpay: { methods, _address: address },
        account,
      } = blockchainData;
      //console.log(address);
      var owner = await methods.owner().call({ from: account });
      var wallet = await methods.wallet().call({ from: account });
      var etherBalance = await web3.eth.getBalance(address);
      const isOwner = account === owner;
      if (1996) {
        const updatedWalletList = gatewayWallets.map(async (wallet) => {
          const fromWeiFunction = (weiValue, fixedPoint = 4) => {
            return parseFloat(web3.utils.fromWei(weiValue, wallet.weiConversion)).toFixed(
              fixedPoint,
            );
          };
          const ERC20Contract = new web3.eth.Contract(
            ERC20.abi,
            wallet.tokenAddress,
            blockchainData.account,
          );
          let allowance, weiBalance;
          if (isOwner) {
            weiBalance = await blockchainData.blockpay.methods
              .getBalance(wallet.tokenAddress)
              .call({ from: blockchainData.account });
          } else {
            if (wallet.isToken) {
              allowance = await ERC20Contract.methods
                .allowance(blockchainData.account, address)
                .call({ from: blockchainData.account });
              weiBalance = await ERC20Contract.methods.balanceOf(blockchainData.account).call();
            } else {
              weiBalance = await web3.eth.getBalance(blockchainData.account);
            }
          }
          const balance = fromWeiFunction(weiBalance);

          return {
            ...wallet,
            ERC20Contract,
            balance,
            weiBalance,
            allowance,
            fromWeiFunction,
          };
        });
        // waiting for all the promises to resolve
        Promise.all(updatedWalletList)
          .then((gatewayWallets) => {
            resolve({ gatewayWallets, owner, wallet, etherBalance: Number(etherBalance) });
          })
          .catch((err) => {
            throw err;
          });
      } else {
        throw new Error("Token contract not deployed to detected network.");
      }
    } catch (error) {
      console.log(error);
      reject(error);
    }
  });
};

export const updateWalletInfo = (wallet, blockchainData) => {
  //("updating wallet Info", blockchainData);
  return new Promise(async (resolve, reject) => {
    try {
      const { ERC20Contract } = wallet;
      const allowance = await ERC20Contract.methods
        .allowance(blockchainData.account, blockchainData.blockpay._address)
        .call({ from: blockchainData.account });
      const weiBalance = await ERC20Contract.methods.balanceOf(blockchainData.account).call();
      const balance = wallet.fromWeiFunction(weiBalance);
      resolve({ ...wallet, allowance, balance, weiBalance });
    } catch (error) {
      console.log(error);
      reject(error);
    }
  });
};

export const getPendingTransactionData = (transactionHash, web3) => {
  return new Promise(async (resolve, reject) => {
    try {
      const reciept = await web3.eth.getTransactionReceipt(transactionHash);
      if (reciept?.status) {
        resolve(reciept);
      } else {
        resolve(false);
      }
    } catch (error) {
      reject(error);
    }
  });
};

export const getTestToken = (selectedCurrency, blockchainData) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { ERC20Contract } = selectedCurrency;
      const { account } = blockchainData;
      const reciept = await ERC20Contract.methods
        .faucet(100000000000000000000n) // equals to 100 token
        .send({ from: account });
      debugger;
      if (reciept?.status) {
        resolve(reciept);
      } else {
        resolve(false);
      }
    } catch (error) {
      console.log(error);
      reject(error);
    }
  });
};
