import React from "react";
import PropTypes from "prop-types";

function PairDisplay(props) {
  const { cryptoValue, IRTValue } = props;
  return (
    <div
      className={`pair-display-wrapper${cryptoValue !== 0 ? " active" : ""}`}
    >
      {/* <p className="crypto-value">{cryptoValue}</p> */}
      <label htmlFor="irt-value" className="crypto-label">
        تومان
      </label>
      <p id="irt-value" className="irt-value">
        {IRTValue.toFixed(3)}
      </p>
    </div>
  );
}

PairDisplay.propTypes = {
  cryptoValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  IRTValue: PropTypes.number.isRequired,
};

export default PairDisplay;
