import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

import DropdownSelect from "./FormComponents/DropdownSelect";
import ValueInput from "./FormComponents/ValueInput";
import ShowSelectedCuerrency from "./FormComponents/ShowSelectedCuerrency";
import CurrentValueDisplay from "./FormComponents/CurrentValueDisplay";
import PairDisplay from "./FormComponents/PairDisplay";
import ValueDisplay from "./FormComponents/ValueDisplay";

function PaymentForm(props) {
  const { walletCurrencyList, handleSubmit, isActive, toWei } = props;

  // state variables
  const [currencyType, setCurrencyType] = useState(walletCurrencyList[0]);
  const [weiValue, setWeiValue] = useState("0");
  const [value, setValue] = useState("0");
  const [canProceed, setCanProceed] = useState(false);
  const handleCurrencyChange = (value) => {
    setCurrencyType(value);
  };
  useEffect(() => {
    try {
      const value = currencyType.fromWeiFunction(weiValue, 6);
      setValue(value);
    } catch (error) {}
  }, [weiValue, currencyType]);
  // Update Selected Currency on change
  useEffect(() => {
    const filtered = walletCurrencyList.filter((wallet) => {
      return (
        wallet.tokenAddress === currencyType.tokenAddress &&
        (!window.payJson || wallet.name === window.payJson.Currency)
      );
    })[0];
    if (filtered && filtered.tokenAddress !== currencyType.tokenAddress) setCurrencyType(filtered);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [walletCurrencyList]);
  // check if the wallet has enough balance
  useEffect(() => {
    // converting the strings to float for accuracy
    const weiVal = parseFloat(weiValue);
    const weiBalance = parseFloat(currencyType.weiBalance);
    setCanProceed(0 < weiVal && weiVal <= weiBalance);
  }, [weiValue, currencyType]);
  // disable and enable buttons.

  const toWeiFunction = (value) => toWei(value, currencyType.weiConversion);

  const cancelAndBackToSite = () => {
    if (window.payJson) {
      const url = window.payJson.CallbackUrl + "?status=0&errors=تراکنش توسط کاربر لغو شد";
      window.location.href = url;
    }
  };
  return (
    <form
      id=""
      className={`form-wrapper withdraw-from-gateway${isActive ? "" : " hidden"}`}
      onSubmit={(ev) => {
        ev.preventDefault();
        handleSubmit(currencyType, weiValue);
      }}
    >
      <ValueDisplay
        name="mechantName"
        label="پرداخت به"
        id="merchang-name-display"
        className="currency-value"
        display={window.payJson?.MerchantName || "نامشخص"}
        icon={window.payJson?.LogoUrl || ""}
      />
      <ValueDisplay
        name="mechantName"
        label="شماره فاکتور"
        id="merchang-name-display"
        className="currency-value"
        display={window.payJson?.FactorNumber || "نامشخص"}
      />
      <ValueDisplay
        name="mechantName"
        label="شماره موبایل"
        id="merchang-name-display"
        className="currency-value"
        display={window.payJson?.MobileNumber || "نامشخص"}
      />
      <DropdownSelect
        name="currencyType"
        label="نوع ارز"
        id="currency-type"
        parentClass="select-currency-type"
        selectClass="select"
        onChangeAction={handleCurrencyChange}
        options={walletCurrencyList}
      />
      <ShowSelectedCuerrency {...currencyType} />
      <ValueInput
        name="currencyValue"
        label="مقدار"
        type="number"
        id="currency-value-input"
        className="currency-value"
        onChangeAction={setWeiValue}
        balance={Number(currencyType.balance)}
        weiBalance={currencyType.weiBalance}
        toWei={toWeiFunction}
        icon={currencyType.name}
        defaultVal={Number(window.payJson?.Total || "0")}
      />
      {false ? (
        <>
          <CurrentValueDisplay exchangeRate={currencyType.exchangeRate} />
          <PairDisplay cryptoValue={value} IRTValue={value * currencyType.exchangeRate} />
        </>
      ) : (
        ""
      )}
      <div className="buttons-wrapper">
        <input
          type="submit"
          disabled={!canProceed}
          className={`button proceed${canProceed ? "" : " disable"}`}
          value="پرداخت"
        />
        <button type="button" className={`button back`} onClick={cancelAndBackToSite}>
          بازگشت
        </button>
      </div>
    </form>
  );
}

PaymentForm.propTypes = {
  walletCurrencyList: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      name: PropTypes.string,
      tokenAddress: PropTypes.string,
      icon: PropTypes.any,
      exchangeRate: PropTypes.number,
      isToken: PropTypes.bool,
      allowance: PropTypes.string,
      balance: PropTypes.string,
      weiBalance: PropTypes.string,
    }),
  ),
  handleSubmit: PropTypes.func,
  isActive: PropTypes.bool,
  toWei: PropTypes.func,
  fromWei: PropTypes.func,
};

export default PaymentForm;
