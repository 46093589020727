import React from "react";
import PropTypes from "prop-types";
//
import * as utility from "../../utility/utility";
import { icons } from "../../utility/icons";
// Assets
import background from "../../../assets/images/coin-background.svg";
function ShowSelectedCuerrency(props) {
  const { name, balance, label, showBalance } = props;
  return (
    <div className="show-currency-wrapper" style={{ backgroundImage: `url(${background})` }}>
      <p className="show-currency-label">{showBalance ? "موجودی: " + balance : label}</p>
      <p className="show-currency-name">{utility.nameToUppercase(name)}</p>
      <i className="show-currency-logo">{icons[`${name}_circ_active`]}</i>
    </div>
  );
}

ShowSelectedCuerrency.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  showBalance: PropTypes.bool,
};
ShowSelectedCuerrency.defaultProps = {
  showBalance: true,
};

export default ShowSelectedCuerrency;
