import { combineReducers } from "redux";
import { reducer as notifications } from "react-notification-system-redux";

import blockchainData, { results } from "./blockchainReducer";
import { successReducer as success } from "./successReducer";

export default combineReducers({
  blockchainData,
  results,
  success,
  notifications,
});
