import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

function TransferOwnership(props) {
  const { transferOwnershipAction, changeWalletAction } = props;
  const [activeForm, setActiveForm] = useState("transferOwnership");
  const [owner, setOwner] = useState("");
  const [wallet, setWallet] = useState("");
  const [ownerIsValid, setOwnerIsValid] = useState(false);
  const [walletIsValid, setWalletIsValid] = useState(false);
  const handleTransferOwnership = (ev) => {
    ev.preventDefault();
    transferOwnershipAction(owner);
  };
  const handleChangeWallet = (ev) => {
    ev.preventDefault();
    changeWalletAction(wallet);
  };

  useEffect(() => {
    // ToDo: check for valid wallet address.
    const validation = owner.trim().length > 0;
    setOwnerIsValid(validation);
  }, [owner]);

  useEffect(() => {
    // ToDo: check for valid wallet address.
    const validation = wallet.trim().length > 0;
    setWalletIsValid(validation);
  }, [wallet]);
  return (
    <div className="transfer-ownership component-wrapper">
      <div className="tabs-container">
        <form
          onSubmit={(ev) => handleTransferOwnership(ev)}
          className={`form-wrapper transfer-ownership${
            activeForm === "transferOwnership" ? "" : " hidden"
          }`}
        >
          <input
            type="text"
            className="text-input owner-address"
            placeholder="انتقال مالکیت"
            value={owner}
            onChange={({ target }) => setOwner(target.value)}
          />
          <button
            type="button"
            onClick={() => {
              setActiveForm("changeWallet");
            }}
            className="text-input to-change-wallet"
          >
            تغییر کیف پول
          </button>
          <input
            type="submit"
            disabled={!ownerIsValid}
            className={`button submit${ownerIsValid ? "" : " disable"}`}
            value="انتقال"
          />
        </form>
        <form
          onSubmit={(ev) => handleChangeWallet(ev)}
          className={`form-wrapper change-wallet${
            activeForm === "changeWallet" ? "" : " hidden"
          }`}
        >
          <button
            type="button"
            className="text-input to-transfer-ownership"
            onClick={() => {
              setActiveForm("transferOwnership");
            }}
          >
            انتقال مالکیت
          </button>
          <input
            name="changeWallet"
            type="text"
            className="text-input wallet-address"
            placeholder="تغییر کیف پول"
            value={wallet}
            onChange={({ target }) => setWallet(target.value)}
          />
          <input
            type="submit"
            disabled={!walletIsValid}
            className={`button submit${walletIsValid ? "" : " disable"}`}
            value="تغییر"
          />
        </form>
      </div>
    </div>
  );
}

TransferOwnership.propTypes = {
  transferOwnershipAction: PropTypes.func,
  changeWalletAction: PropTypes.func,
};

export default TransferOwnership;
