import React from 'react'
import PropTypes from 'prop-types'

function CurrentValueDisplay({exchangeRate}) {
  const currencyMask = (value => {
    // ToDo: Complete later
    return value
  })
  return (
    <p className="current-exchange-rate">{currencyMask(exchangeRate)}</p>
  )
}

CurrentValueDisplay.propTypes = {
  exchangeRate: PropTypes.number.isRequired,
}

export default CurrentValueDisplay

