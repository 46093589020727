import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

// Components
import DropdownSelect from "./FormComponents/DropdownSelect";
import ValueInput from "./FormComponents/ValueInput";
import WalletAdressInput from "./FormComponents/WalletAdressInput";
import ShowSelectedCuerrency from "./FormComponents/ShowSelectedCuerrency";
import CurrentValueDisplay from "./FormComponents/CurrentValueDisplay";

function TransferTo(props) {
  const { walletCurrencyList, handleSubmit, isActive, toWei } = props;
  const [currencyType, setCurrencyType] = useState(walletCurrencyList[0]);
  const [setValue] = useState(0);
  const [weiValue, setWeiValue] = useState("0");
  const [address, setAddress] = useState("");
  const [valueIsValid, setValueIsValid] = useState(false);
  const [addressIsValid, setAddressIsValid] = useState(false);

  const handleCurrencyChange = (value) => {
    setWeiValue(0);
    setValue(0);
    setCurrencyType(value);
  };
  useEffect(() => {
    const weiVal = parseFloat(weiValue);
    const weiBalance = parseFloat(currencyType.weiBalance);
    if (weiVal > 0 && weiVal <= weiBalance) setValueIsValid(true);
    else setValueIsValid(false);
  }, [weiValue, currencyType, valueIsValid]);

  useEffect(() => {
    // ToDo: check for valid address;
    if (address.length > 0) setAddressIsValid(true);
    else setAddressIsValid(false);
  }, [address, addressIsValid]);
  const toWeiFunction = (value) => toWei(value, currencyType.weiConversion);

  useEffect(() => {
    try {
      const value = currencyType.fromWeiFunction(weiValue, 6);
      setValue(value);
    } catch (error) {}
  }, [weiValue, currencyType]);
  return (
    <form
      id=""
      className={`form-wrapper withdraw-from-gateway${isActive ? "" : " hidden"}`}
      onSubmit={(ev) => {
        ev.preventDefault();
        handleSubmit(currencyType, address, weiValue);
      }}
    >
      <DropdownSelect
        name="currencyType"
        label="نوع ارز"
        id="currency-type"
        parentClass="select-currency-type"
        selectClass="select"
        onChangeAction={handleCurrencyChange}
        options={walletCurrencyList}
      />
      <ShowSelectedCuerrency {...currencyType} showBalance={false} />
      <ValueInput
        name="currencyValue"
        label="مقدار"
        type="number"
        id="currency-value-input"
        className="currency-value"
        // icon={currencyType.name}
        onChangeAction={setWeiValue}
        balance={Number(currencyType.balance)}
        weiBalance={currencyType.weiBalance}
        toWei={toWeiFunction}
      />
      <CurrentValueDisplay exchangeRate={currencyType.exchangeRate} />
      <WalletAdressInput
        name="destinationAddressAdress"
        label="به آدرس"
        type="string"
        id="wallet-address-input"
        className="wallet-adress"
        icon={currencyType.name}
        onChangeAction={setAddress}
      />
      <input
        type="submit"
        disabled={!valueIsValid && addressIsValid}
        className={`button approve${valueIsValid && addressIsValid ? "" : " disable"}`}
        value="برداشت"
      />
    </form>
  );
}

TransferTo.propTypes = {
  walletCurrencyList: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      name: PropTypes.string,
      tokenAddress: PropTypes.string,
      icon: PropTypes.any,
      exchangeRate: PropTypes.number,
    }),
  ),
  handleSubmit: PropTypes.func,
  isActive: PropTypes.bool,
};

export default TransferTo;
