export const transactionList = {
  transferOwnwerhip: "TRANSFER_OWNERSHIP",
  changeWallet: "CHANGE_WALLET",
  approveToken: "APPROVE_TOKEN",
  transferToken: "TRANSFER_TOKEN",
  transferCoin: "TRANSFER_COIN",
};
/**
 *
 * @param {String} transaction - transaction type, derive from 'src/components/utility/errors.js.transactionList'
 * @param {Object} blockpay
 */
export function setEvents(blockpay, transaction) {
  switch (transaction) {
    case transactionList.transferOwnership:
      blockpay.events
        .OwnershipTransferred((error, event) => {
          console.log(event);
        })
        .on("data", (event) => {})
        .on("changed", (event) => {
          console.log("changed, ", event);
        })
        .on("error", (error, receipt) => {
          console.log(error, receipt);
          // parse the error
        });
      return blockpay;
    case transactionList.changeWallet:
      blockpay.events
        .WalletChanged((error, event) => {})
        .on("data", (event) => {
          // do something with data
          console.log("wallet changed 2", event);
        })
        .on("changed", (event) => {
          console.log("changed, ", event);
        })
        .on("error", (error, receipt) => {
          console.log(error, receipt);
          // parse the error
        });
      return blockpay;
    case transactionList.transferCoin:
      blockpay.events
        .CoinTransferred((error, event) => {})
        .on("data", (event) => {
          // do something with data
          console.log("transfer ether event:", event);
        })
        .on("changed", (event) => {
          console.log("changed, ", event);
        })
        .on("error", (error, receipt) => {
          console.log(error, receipt);
          // parse the error
        });
      return blockpay;
    case transactionList.transferToken:
      blockpay.events
        .TokenTransferred((error, event) => {})
        .on("data", (event) => {
          // do something with data
          console.log("transfer ether event:", event);
        })
        .on("changed", (event) => {
          console.log("changed, ", event);
        })
        .on("error", (error, receipt) => {
          console.log(error, receipt);
          // parse the error
        });
      return blockpay;
    default:
      return blockpay;
  }
}
