import React from "react";
import { success as successNotif, error as errorNotif } from "react-notification-system-redux";

import { store } from "../../";

import { SET_TRANSACTION_HISTORY_DATA } from "../actionTypes";
export const showSuccessNotification = (message, transactionHash, paramList) => {
  return successNotif({
    message,
    position: "tr",
    autoDismiss: 10,
    children: (
      <ul className="notification-data-wrapper">
        {paramList.map(({ label, value }, idx) => (
          <li key={idx} className="notification-data-item">
            <label htmlFor={`item-${idx}`} className="item-label">
              {label}:
            </label>
            <p id={`item-${idx}`} className="item-value ltr">
              {value}
            </p>
          </li>
        ))}
        <li key="link_to_etherscan" className="notification-data-item" style={{ display: "none" }}>
          <label htmlFor="link-to-etherscan" className="item-label">
            مشاهده در اتراسکن:
          </label>
          <a
            className="item-value ltr"
            rel="noreferrer"
            target="_blank"
            href={`https://rinkeby.etherscan.io/tx/${transactionHash}`}
          >
            <p className="item-value ltr">{transactionHash}</p>
          </a>
        </li>
      </ul>
    ),
  });
};

export const setConfirmation = (number, reciept, blockHash) => {
  const { transactionHash } = reciept;
  store.dispatch({
    type: SET_TRANSACTION_HISTORY_DATA,
    payload: { [transactionHash]: { number, reciept, blockHash } },
  });
};
export const setTransactionHash = (error, transactionHash) => {
  if (error) {
    console.log(error);
    errorNotif({
      message: error.message + error.code,
    });
    return;
  }
  store.dispatch({
    type: SET_TRANSACTION_HISTORY_DATA,
    payload: { [transactionHash]: {} },
  });
};
