import React, { useState } from "react";
import PropTypes from "prop-types";

function TabSelector({ name, tabs, handleChange, className }) {
  const [selected, setSelected] = useState(tabs[0].value);
  const handleTabChange = (value, { target }, idx) => {
    document.querySelector(
      ".selected-indicator"
    ).style.transform = `translateX(${-target.offsetWidth * idx}px)`;
    handleChange(name, value);
    setSelected(value);
  };
  return (
    <div className="tabs">
      <ul className={`tab-wrapper${className ? ` ${className}` : ""}`}>
        {tabs.map(({ label, value, icon }, idx) => (
          <li
            key={`tab-${idx}`}
            className={`tab-element${value === selected ? " selected" : ""}`}
            onClick={(ev) => handleTabChange(value, ev, idx)}
          >
            <p className="tab-label">{label}</p>
            {icon && <img alt={value} src={icon} className="tab-icon"></img>}
          </li>
        ))}
      </ul>
      <span className="selected-indicator" />
    </div>
  );
}

TabSelector.propTypes = {
  name: PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired,
  className: PropTypes.string,
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      icon: PropTypes.any,
    })
  ),
};
TabSelector.defaultProps = {
  handleChange: () => {},
};

export default TabSelector;
