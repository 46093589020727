import React, { useReducer, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { icons } from "../../utility/icons";

// Utility
import * as utility from "../../utility/utility";
const WalletBalanceDisplay = (props) => {
  const { options, onChangeAction } = props;
  const [isOpen, setIsOpen] = useState(false);
  const [selectedValue, handleSelect] = useReducer((_current, option) => {
    setIsOpen(false);
    onChangeAction && onChangeAction(option);
    return option;
  }, options[0]);

  // update values when new props recieved:

  useEffect(
    (prevOptions, _prevSelected) => {
      if (options !== prevOptions) {
        //console.log("new Props recieved");
        const filtered = options.filter(
          (option) => option.tokenAddress === selectedValue.tokenAddress,
        )[0];
        if (filtered) handleSelect(filtered);
      }
    },
    [options, selectedValue],
  );

  return (
    <div className="balance-display-wrapper">
      <div className="input-wrapper">
        <div className="select-box" onClick={() => setIsOpen(!isOpen)}>
          <i className="option-logo">{icons[`${selectedValue.name}_circ_active`]}</i>
          <p className="option-label">{selectedValue.label}</p>
          <p className="option-label-latin">{utility.nameToUppercase(selectedValue.name)}</p>
        </div>
        <i className={`select-arrow ${isOpen ? "open" : "close"}`}>
          <span className="icon-arrow"></span>
        </i>
      </div>
      <ul className={`dropdown-wrapper ${isOpen ? "show" : "hide"}`}>
        {options.map((option, key) => (
          <li
            key={`option-${key}`}
            className={`dropdown-option${option.value === selectedValue ? " selected" : ""}`}
            onClick={() => handleSelect(option)}
          >
            <i className="option-logo">{icons[`${option.name}_circ_active`]}</i>
            <p className="option-label">{option.label}</p>
          </li>
        ))}
      </ul>
      <div className="currecny-balance-display">
        <p className="balance">{selectedValue.balance}</p>
        <p className="irt-balance">{selectedValue.IRTBalance}</p>
      </div>
    </div>
  );
};

WalletBalanceDisplay.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  id: PropTypes.string,
  onChangeAction: PropTypes.func,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      label: PropTypes.string.isRequired,
      tokenAddress: PropTypes.string.isRequired,
      icon: PropTypes.any,
      balance: PropTypes.string,
      IRTBalance: PropTypes.number,
    }),
  ),
};

WalletBalanceDisplay.defaultProps = {};

export default WalletBalanceDisplay;
