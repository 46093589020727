import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

// actions && methods:
import {
  approveToken,
  buyEther,
  buyToken,
  depositEther,
  depositToken,
} from "../../store/actions/consumerActions";
// Components:
import PaymentForm from "./PaymentForm";
import ModalOverlay from "./ModalOverlay";
import Loader from "./Loader";

// Assets
// import toGateway from "../../assets/images/to-gateway-image.svg";
// import direct from "../../assets/images/to-wallet-image.svg";

class GatewayUserPage extends Component {
  static propTypes = {
    web3Utils: PropTypes.object,
    gatewayWallets: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
        name: PropTypes.string,
        tokenAddress: PropTypes.string,
        icon: PropTypes.any,
        ERC20Contract: PropTypes.object,
      }),
    ),
    deposit: PropTypes.func,
    buy: PropTypes.func,
  };
  state = {
    loading: true,
    paymentMethod: "toGateway",
    gatewayWallets: [],
    currencyType: {},
    isTransactionInProgress: false,
    transactionInfo: null,
  };
  componentDidMount() {
    const { gatewayWallets } = this.props;
    this.setState({
      gatewayWallets,
      currencyType: gatewayWallets[0],
      loading: false,
      isTransactionInProgress: false,
      transactionInfo: null,
    });
  }

  handlePaymentToGatewayFormSubmit = async (currencyType, weiAmount) => {
    try {
      this.setState({ isTransactionInProgress: true });
      if (currencyType.isToken) {
        await this.props.depositToken(weiAmount, currencyType);
      } else {
        await this.props.depositEther(weiAmount, currencyType);
      }
      this.setState({
        transactionInfo: null,
        isTransactionInProgress: false,
      });
    } catch (error) {
      this.setState({
        transactionInfo: null,
        isTransactionInProgress: false,
      });
      console.log(error);
    }
  };

  handleTokenDeposit = async (weiAmount, currencyType) => {
    this.setState({ isTransactionInProgress: true });
    try {
      const res = await this.props.approveToken(weiAmount, currencyType);
      console.log(res);
    } catch (error) {
      console.log(error);
    }
    this.setState({ isTransactionInProgress: false });
  };

  handleDirectPaymentFormSubmit = async (currencyType, weiAmount) => {
    this.setState({ isTransactionInProgress: true });
    try {
      if (currencyType.isToken) {
        await this.props.buyToken(weiAmount, currencyType);
      } else {
        await this.props.buyEther(weiAmount, currencyType);
      }
      this.setState({ isTransactionInProgress: true });
    } catch (error) {
      this.setState({
        transactionInfo: null,
        isTransactionInProgress: false,
      });
      console.log(error);
    }
  };

  render() {
    return (
      <>
        <div className="confirm-box-style user-page">
          <div className="form-divider right fixed">
            <PaymentForm
              walletCurrencyList={this.props.gatewayWallets}
              handleSubmit={this.handlePaymentToGatewayFormSubmit}
              isActive={this.state.paymentMethod === "toGateway"}
              toWei={this.props.web3Utils.toWei}
              fromWei={this.props.web3Utils.fromWei}
            />
          </div>
        </div>
        {this.state.isTransactionInProgress ? (
          <ModalOverlay>
            <Loader />
          </ModalOverlay>
        ) : (
          ""
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  gatewayWallets: state.blockchainData.blockchainData.gatewayWallets,
  web3Utils: state.blockchainData.blockchainData.web3.utils,
  account: state.blockchainData.blockchainData.account,
});

const mapDispatchToProps = {
  approveToken,
  buyEther,
  buyToken,
  depositEther,
  depositToken,
};

export default connect(mapStateToProps, mapDispatchToProps)(GatewayUserPage);
