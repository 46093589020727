import { transactionList, setEvents } from "../../components/utility/events";

import { setConfirmation, setTransactionHash } from "../actions/successActions";

export const approveToken = (weiAmount, selectedCurrency, blockchainData) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { account, blockpay } = blockchainData;
      const { ERC20Contract } = selectedCurrency;
      ERC20Contract.methods
        .approve(blockpay._address, weiAmount)
        .send({ from: account }, setTransactionHash)
        .on("receipt", (receipt) => {
          resolve(receipt);
        })
        .on("confirmation", setConfirmation)
        .on("error", (error, _receipt) => {
          reject(error);
        });
    } catch (error) {
      console.log(error);
      reject(error);
    }
  });
};

export const depositEther = (weiAmount, destinationAddress, blockchainData) => {
  return new Promise((resolve, reject) => {
    try {
      const { blockpay, account } = blockchainData;
      blockpay.methods
        .depositEther(weiAmount)
        .send({ from: account, value: weiAmount }, setTransactionHash)
        .on("receipt", (receipt) => {
          resolve(receipt);
        })
        .on("confirmation", setConfirmation)
        .on("error", (error, _receipt) => {
          reject(error);
        });
      setEvents(blockpay, transactionList.transferToken);
    } catch (error) {
      console.log(error);
      reject(error);
    }
  });
};

export const depositToken = (weiAmount, selectedCurrency, blockchainData, dispatch) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { blockpay, account } = blockchainData;
      const { ERC20Contract, tokenAddress } = selectedCurrency;
      console.log(ERC20Contract, tokenAddress);
      ERC20Contract.methods
        .transfer(blockpay._address, weiAmount)
        .send({ from: account }, setTransactionHash)
        .on("receipt", (receipt) => {
          resolve(receipt);
        })
        .on("confirmation", setConfirmation)
        .on("error", (error, _receipt) => {
          reject(error);
        });
      setEvents(blockpay, transactionList.transferToken);
    } catch (error) {
      console.log(error);
      reject(error);
    }
  });
};

export const buyEther = (weiAmount, destinationAddress, blockchainData) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { blockpay, account } = blockchainData;
      blockpay.methods
        .buy(weiAmount, destinationAddress)
        .send({ from: account, value: weiAmount }, setTransactionHash)
        .on("receipt", (receipt) => {
          resolve(receipt);
        })
        .on("confirmation", setConfirmation)
        .on("error", (error, _receipt) => {
          reject(error);
        });
      setEvents(blockpay, transactionList.transferCoin);
    } catch (error) {
      reject(error);
    }
  });
};

export const buyToken = (weiAmount, destinationAddress, blockchainData) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { blockpay, account } = blockchainData;
      console.log(destinationAddress, account, weiAmount);
      blockpay.methods
        .buy(weiAmount, destinationAddress)
        .send({ from: account }, setTransactionHash)
        .on("receipt", (receipt) => {
          resolve(receipt);
        })
        .on("confirmation", setConfirmation)
        .on("error", (error, _receipt) => {
          reject(error);
        });
      setEvents(blockpay, transactionList.transferToken);
    } catch (error) {
      console.log(error);
      reject(error);
    }
  });
};
