import * as consumerMethods from "../async/consumerMethods";
import { setTransactionResults, setUpdatedWallet } from "./blockchainActions";
import { updateWalletInfo } from "../async/blockchainMethods";
import { handleError } from "./errorActions";
import { showSuccessNotification, setConfirmation } from "./successActions";
import { error as errorNotif } from "react-notification-system-redux";

export const sendTransactionApproval = (number, reciept, blockHash) => {
  console.log(number, reciept, blockHash);
  return (dispatch) => {
    dispatch(setConfirmation(number, reciept, blockHash));
  };
};

export const approveToken = (weiAmount, selectedCurrency) => {
  return async (dispatch, getState) => {
    const blockchainData = getState().blockchainData.blockchainData;
    try {
      const res = await consumerMethods.approveToken(
        weiAmount,
        selectedCurrency,
        blockchainData
      );
      dispatch(
        showSuccessNotification(
          "تایید انتقال با موفقیت انجام شد.",
          res.transactionHash,
          [
            {
              label: "آدرس درگاه",
              value: res.events.Approval.returnValues.owner,
            },
            {
              label: "نوع توکن",
              value: selectedCurrency.label,
            },
            {
              label: "مبلغ",
              value: selectedCurrency.fromWeiFunction(
                res.events.Approval.returnValues.value,
                6
              ),
            },
          ]
        )
      );
      const updatedWallet = await updateWalletInfo(
        selectedCurrency,
        blockchainData
      );
      dispatch(setUpdatedWallet(updatedWallet));
      return res;
    } catch (err) {
      const errorMessage = handleError(err);
      if (errorMessage) {
        dispatch(errorNotif(errorMessage));
      }
      throw err;
    }
  };
};

export const depositToken = (weiAmount, selectedCurrency) => {
  return async (dispatch, getState) => {
    const blockchainData = getState().blockchainData.blockchainData;
    try {
      const res = await consumerMethods.depositToken(
        weiAmount,
        selectedCurrency,
        blockchainData
      );
      console.log(res);
      const { from, to, value } = res.events.Transfer.returnValues;
      const result = {
        message: "نوع تراکنش: پرداخت توکن به درگاه.",
        params: [
          {
            label: "فرستنده",
            value: from,
          },
          {
            label: "دریافت کننده",
            value: to,
          },
          {
            label: "نوع توکن",
            value: selectedCurrency.label,
          },
          {
            label: "مبلغ",
            value: selectedCurrency.fromWeiFunction(value, 6),
          },
        ],
        transactionHash: res.transactionHash,
        requiredConfirmations: 5,
      };
      dispatch(setTransactionResults(result));
      return res;
    } catch (err) {
      const errorMessage = handleError(err);
      if (errorMessage) {
        dispatch(errorNotif(errorMessage));
      }
      throw err;
    }
  };
};

export const depositEther = (weiAmount, selectedCurrency) => {
  return async (dispatch, getState) => {
    const blockchainData = getState().blockchainData.blockchainData;
    try {
      const res = await consumerMethods.depositEther(
        weiAmount,
        selectedCurrency.tokenAddress,
        blockchainData
      );
      console.log(res);
      const { sender, receiver, value } =
        res.events.CoinTransferred.returnValues;
      const result = {
        message: "نوع تراکنش: پرداخت اتر به درگاه.",
        params: [
          {
            label: "فرستنده",
            value: sender,
          },
          {
            label: "دریافت کننده",
            value: receiver,
          },
          {
            label: "مبلغ",
            value: selectedCurrency.fromWeiFunction(value, 6),
          },
        ],
        transactionHash: res.transactionHash,
        requiredConfirmations: 5,
      };
      dispatch(setTransactionResults(result));
      return res;
    } catch (err) {
      const errorMessage = handleError(err);
      if (errorMessage) {
        dispatch(errorNotif(errorMessage));
      }
      throw err;
    }
  };
};

export const buyEther = (weiAmount, selectedCurrency) => {
  return async (dispatch, getState) => {
    const blockchainData = getState().blockchainData.blockchainData;
    try {
      const res = await consumerMethods.buyEther(
        weiAmount,
        selectedCurrency.tokenAddress,
        blockchainData
      );
      console.log(res);
      const { sender, receiver, value } =
        res.events.CoinTransferred.returnValues;
      const result = {
        message: "نوع تراکنش: انتقال اتر.",
        params: [
          {
            label: "فرستنده",
            value: sender,
          },
          {
            label: "دریافت کننده",
            value: receiver,
          },
          {
            label: "مبلغ",
            value: selectedCurrency.fromWeiFunction(value, 6),
          },
        ],
        transactionHash: res.transactionHash,
        requiredConfirmations: 5,
      };
      dispatch(setTransactionResults(result));
      return res;
    } catch (err) {
      const errorMessage = handleError(err);
      if (errorMessage) {
        dispatch(errorNotif(errorMessage));
      }
      throw err;
    }
  };
};

export const buyToken = (weiAmount, selectedCurrency) => {
  return async (dispatch, getState) => {
    const blockchainData = getState().blockchainData.blockchainData;
    try {
      const res = await consumerMethods.buyToken(
        weiAmount,
        selectedCurrency.tokenAddress,
        blockchainData
      );
      console.log(res);
      const { sender, receiver, value } =
        res.events.TokenTransferred.returnValues;
      const result = {
        message: "نوع تراکنش: انتقال توکن.",
        params: [
          {
            label: "فرستنده",
            value: sender,
          },
          {
            label: "دریافت کننده",
            value: receiver,
          },
          {
            label: "نوع توکن",
            value: selectedCurrency.label,
          },
          {
            label: "مبلغ",
            value: selectedCurrency.fromWeiFunction(value, 6),
          },
        ],
        transactionHash: res.transactionHash,
        requiredConfirmations: 5,
      };
      dispatch(setTransactionResults(result));
      return res;
    } catch (err) {
      const errorMessage = handleError(err);
      if (errorMessage) {
        dispatch(errorNotif(errorMessage));
      }
      throw err;
    }
  };
};
