import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

import DropdownSelect from "./FormComponents/DropdownSelect";
import ValueInput from "./FormComponents/ValueInput";
import ShowSelectedCuerrency from "./FormComponents/ShowSelectedCuerrency";
import CurrentValueDisplay from "./FormComponents/CurrentValueDisplay";
import PairDisplay from "./FormComponents/PairDisplay";

function WithdrawForm(props) {
  const { walletCurrencyList, handleSubmit, isActive, toWei } = props;
  const [currencyType, setCurrencyType] = useState(walletCurrencyList[0]);
  const [value, setValue] = useState(0);
  const [weiValue, setWeiValue] = useState("0");
  const [canProceed, setCanProceed] = useState(false);

  const handleCurrencyChange = (value) => {
    setValue(0);
    setCurrencyType(value);
  };

  useEffect(() => {
    const weiVal = parseFloat(weiValue);
    const weiBalance = parseFloat(currencyType.weiBalance);
    if (weiVal > 0 && weiVal <= weiBalance) setCanProceed(true);
    else setCanProceed(false);
  }, [weiValue, currencyType]);

  useEffect(() => {
    try {
      const value = currencyType.fromWeiFunction(weiValue, 6);
      setValue(value);
    } catch (error) {}
  }, [weiValue, currencyType]);

  const toWeiFunction = (value) => toWei(value, currencyType.weiConversion);

  return (
    <form
      id=""
      className={`form-wrapper withdraw-from-gateway${isActive ? "" : " hidden"}`}
      onSubmit={(ev) => {
        ev.preventDefault();
        //console.log(weiValue);
        handleSubmit(currencyType, weiValue);
      }}
    >
      <DropdownSelect
        name="currencyType"
        label="نوع ارز"
        id="currency-type"
        parentClass="select-currency-type"
        selectClass="select"
        onChangeAction={handleCurrencyChange}
        options={walletCurrencyList}
      />
      <ShowSelectedCuerrency {...currencyType} showBalance={false} />
      <ValueInput
        name="currencyValue"
        label="مقدار"
        type="number"
        id="currency-value-input"
        className="currency-value"
        // icon={currencyType.name}
        onChangeAction={setWeiValue}
        balance={Number(currencyType.balance)}
        weiBalance={currencyType.weiBalance}
        toWei={toWeiFunction}
      />
      <CurrentValueDisplay exchangeRate={currencyType.exchangeRate} />
      <PairDisplay cryptoValue={value} IRTValue={value * currencyType.exchangeRate} />
      <input
        type="submit"
        disabled={!canProceed}
        className={`button approve${canProceed ? "" : " disable"}`}
        value="برداشت"
      />
    </form>
  );
}

WithdrawForm.propTypes = {
  walletCurrencyList: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      name: PropTypes.string,
      tokenAddress: PropTypes.string,
      icon: PropTypes.any,
      exchangeRate: PropTypes.number,
      isToken: PropTypes.bool,
      balance: PropTypes.string,
      weiBalance: PropTypes.string,
    }),
  ),
  handleSubmit: PropTypes.func,
  isActive: PropTypes.bool,
  toWei: PropTypes.func,
  fromWei: PropTypes.func,
};

export default WithdrawForm;
