import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

// actions && methods:
import {
  transferOwnership,
  changeWallet,
  withdraw,
  transferTo,
} from "../../store/actions/ownerActions";

// Components:
import TabSelector from "./FormComponents/TabSelector";
import TransferOwnership from "./FormComponents/TransferOwnership";
import WalletBalanceDisplay from "./FormComponents/WalletBalanceDisplay";
import WithdrawForm from "./WithdrawForm";
import TransferToForm from "./TransferToForm";
import ModalOverlay from "./ModalOverlay";
import Loader from "./Loader";
import { error as errorNotif } from "react-notification-system-redux";

class GatewayOwnerPage extends Component {
  static propTypes = {
    blockchainData: PropTypes.shape({
      web3: PropTypes.object,
      gatewayWallets: PropTypes.arrayOf(
        PropTypes.shape({
          label: PropTypes.string,
          name: PropTypes.string,
          tokenAddress: PropTypes.string,
          icon: PropTypes.any,
          ERC20Contract: PropTypes.object,
          balance: PropTypes.string,
        }),
      ),
      account: PropTypes.string,
      owner: PropTypes.string,
      wallet: PropTypes.string,
      blockpay: PropTypes.object,
    }),
    transferOwnership: PropTypes.func,
    changeWallet: PropTypes.func,
    withdraw: PropTypes.func,
    transferTo: PropTypes.func,
  };
  state = {
    loading: true,
    withdrawMethod: "withdraw",
    currencyType: {},
    isTransactionInProgress: false,
    transactionInfo: null,
  };
  componentDidMount() {
    this.setState({
      currencyType: this.props.gatewayWallets[0],
      loading: false,
      isTransactionInProgress: false,
      transactionInfo: null,
    });
  }

  handleCurrencyTypeChange = (currencyType) => {
    this.setState({ currencyType });
  };
  handleWithdrawFormSubmit = async (currencyType, weiAmount) => {
    try {
      this.setState({ isTransactionInProgress: true });
      await this.props.withdraw(weiAmount, currencyType, this.props.blockchainData);
      this.setState({ isTransactionInProgress: false });
    } catch (error) {
      console.log(error);
      // Do Anything with the error!
      this.setState({ isTransactionInProgress: false });
    }
  };
  handleTransferFormSubmit = async (currencyType, address, weiAmount) => {
    try {
      this.setState({ isTransactionInProgress: true });
      await this.props.transferTo(address, weiAmount, currencyType, this.props.blockchainData);
      this.setState({ isTransactionInProgress: false });
    } catch (error) {
      console.log(error);
      // Do Anything with the error!
      this.setState({ isTransactionInProgress: false });
    }
  };
  handleTransferOwnership = async (newOwner) => {
    if (newOwner === this.props.wallet) {
      this.props.errorNotif({
        title: "خطا در تراکنش",
        message: "آدرس وارد شده مالک فعلی درگاه است.",
      });
      return;
    }
    try {
      this.setState({ isTransactionInProgress: true });
      const res = await this.props.transferOwnership(newOwner, this.props.blockchainData);
      console.log(res.events);
      this.setState({ isTransactionInProgress: false });
    } catch (error) {
      console.log(error);
      // Do Anything with the error!
      this.setState({ loading: false });
      this.setState({ isTransactionInProgress: false });
    }
  };
  handleChangeWallet = async (address) => {
    if (address === this.props.wallet) {
      this.props.errorNotif({
        title: "خطا در تراکنش",
        message: "کیف پول وارد شده در حال حاضر به درگاه متصل است.",
      });
      return;
    }
    this.setState({ isTransactionInProgress: true });
    try {
      const res = await this.props.changeWallet(address, this.props.blockchainData);
      console.log(res);
      this.setState({ isTransactionInProgress: false });
    } catch (error) {
      console.log(error);
      // Do Anything with the error!
      this.setState({ isTransactionInProgress: false });
    }
  };
  render() {
    if (this.state.loading) {
      return (
        <ModalOverlay>
          <Loader />
        </ModalOverlay>
      );
    } else {
      return (
        <>
          <div className="confirm-box-style">
            <div className="page-wrapper two-column">
              <div className="form-divider left">
                <TabSelector
                  name="withdrawMethod"
                  className="long"
                  handleChange={(name, value) => {
                    this.setState({ [name]: value });
                  }}
                  tabs={[
                    { value: "withdraw", label: "برداشت از درگاه" },
                    { value: "transfer", label: "انتقال به کیف پول دیگر" },
                  ]}
                />
                <WithdrawForm
                  walletCurrencyList={this.props.gatewayWallets}
                  handleSubmit={this.handleWithdrawFormSubmit}
                  isActive={this.state.withdrawMethod === "withdraw"}
                  toWei={this.props.web3Utils.toWei}
                  fromWei={this.props.web3Utils.fromWei}
                />
                <TransferToForm
                  walletCurrencyList={this.props.gatewayWallets}
                  handleSubmit={this.handleTransferFormSubmit}
                  isActive={this.state.withdrawMethod === "transfer"}
                  toWei={this.props.web3Utils.toWei}
                  fromWei={this.props.web3Utils.fromWei}
                />
              </div>
              <div className="form-divider right">
                <WalletBalanceDisplay
                  name="displayEalletBalance"
                  label="موجودی"
                  id="wallet-balance-display"
                  options={this.props.gatewayWallets}
                  toWei={this.props.web3Utils.toWei}
                  fromWei={this.props.web3Utils.fromWei}
                />
                <TransferOwnership
                  transferOwnershipAction={this.handleTransferOwnership}
                  changeWalletAction={this.handleChangeWallet}
                />
              </div>
            </div>
          </div>
          {this.state.isTransactionInProgress ? (
            <ModalOverlay>
              <Loader />
            </ModalOverlay>
          ) : (
            ""
          )}
        </>
      );
    }
  }
}

const mapStateToProps = (state) => ({
  ...state.blockchainData,
  ...state.transactionResult,
  web3Utils: state.blockchainData.blockchainData.web3.utils,
  account: state.blockchainData.blockchainData.account,
  wallet: state.blockchainData.blockchainData.wallet,
  gatewayWallets: state.blockchainData.blockchainData.gatewayWallets,
});

const mapDispatchToProps = {
  transferOwnership,
  changeWallet,
  withdraw,
  transferTo,
  errorNotif,
};

export default connect(mapStateToProps, mapDispatchToProps)(GatewayOwnerPage);
