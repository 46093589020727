import React from "react";
export const icons = {
  tether_circ_active: (
    <span className="font-icon icon-tether-circ-active">
      <span className="path1"></span>
      <span className="path2"></span>
      <span className="path3"></span>
      <span className="path4"></span>
      <span className="path5"></span>
      <span className="path6"></span>
    </span>
  ),
  tether_rect_inactive: (
    <span className="font-icon icon-tether-rect-inactive-1"></span>
  ),
  tether_rect_active: (
    <span className="font-icon icon-tether-rect-active"></span>
  ),
  ether_circ_active: (
    <span className="font-icon icon-ether-circ-active">
      <span className="path1"></span>
      <span className="path2"></span>
      <span className="path3"></span>
      <span className="path4"></span>
      <span className="path5"></span>
      <span className="path6"></span>
      <span className="path7"></span>
      <span className="path8"></span>
      <span className="path9"></span>
      <span className="path10"></span>
      <span className="path11"></span>
      <span className="path12"></span>
    </span>
  ),
  ether_rect_inactive: (
    <span className="icon-tether-rect-inactive">
      <span className="path1"></span>
      <span className="path2"></span>
      <span className="path3"></span>
    </span>
  ),
  ether_rect_active: (
    <span className="icon-ether-rect-active">
      <span className="path1"></span>
      <span className="path2"></span>
      <span className="path3"></span>
    </span>
  ),
  dai_circ_active: (
    <span className="font-icon icon-dai-circ-active">
      <span className="path1"></span>
      <span className="path2"></span>
      <span className="path3"></span>
      <span className="path4"></span>
      <span className="path5"></span>
    </span>
  ),
  dai_rect_inactive: (
    <span className="font-icon icon-dai-rect-inactive">
      <span className="path1"></span>
      <span className="path2"></span>
    </span>
  ),
  dai_rect_active: (
    <span className="font-icon icon-dai-rect-active">
      <span className="path1"></span>
      <span className="path2"></span>
    </span>
  ),
  wbtc_circ_active: (
    <span className="font-icon icon-wbtc-circ-active">
      <span className="path1"></span>
      <span className="path2"></span>
      <span className="path3"></span>
      <span className="path4"></span>
      <span className="path5"></span>
    </span>
  ),
  wbtc_rect_inactive: (
    <span className="font-icon icon-wbtc-rect-inactive">
      <span className="path1"></span>
      <span className="path2"></span>
    </span>
  ),
  wbtc_rect_active: (
    <span className="font-icon icon-wbtc-rect-active">
      <span className="path1"></span>
      <span className="path2"></span>
    </span>
  ),
};
