import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

function WalletAdressInput(props) {
  const {
    name,
    label,
    id,
    className,
    onChangeAction,
    error,
    touched,
    icon,
  } = props;

  const [value, setValue] = useState("");
  const [isActive, setIsActive] = useState(false);
  const handleChange = (ev) => {
    let value = ev.target.value;
    setValue(value);
    if (value.length > 0) {
      setIsActive(true);
    } else {
      setIsActive(false);
    }
    onChangeAction && onChangeAction(value);
  };
  useEffect(() => {
    setValue("");
    setIsActive(false);
    onChangeAction(0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [icon]);
  return (
    <div
      id={`${name + "-" + id}`}
      className={`form-group${className ? ` ${className}` : ""}`}
    >
      <label className="input-label" htmlFor={name}>
        {label}
      </label>
      <div className="input-wrapper">
        <input
          value={value}
          onChange={handleChange}
          type="text"
          className="text-input"
        />
        <i className="input-logo">
          <span
            className={`icon-document-${isActive ? "active" : "inactive"}`}
          ></span>
        </i>
      </div>
      {error && touched && <p className="error">{error}</p>}
    </div>
  );
}

WalletAdressInput.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  id: PropTypes.string,
  className: PropTypes.string,
  onChangeAction: PropTypes.func,
  icon: PropTypes.any,
};

WalletAdressInput.defaultProps = {
  type: "text",
};

export default WalletAdressInput;
