import React from 'react';
import PropTypes from 'prop-types';

function LoginOptionCard(props) {
  const { icon, title, content, action } = props;
  return (
    <button onClick={action} className='single-wallet-box'>
      <img src={icon} className='wallets-logo-style' alt='' />
      <p className='single-wallet-title'>{title}</p>
      <p className='description-wallet-title'>{content}</p>
    </button>
  );
}

LoginOptionCard.propTypes = {
  icon: PropTypes.any,
  action: PropTypes.func,
  title: PropTypes.string,
  content: PropTypes.string,
};

export default LoginOptionCard;
