export const dispatchBlockchainError = (error, origin) => {
  return (dispatch) =>
    dispatch({
      type: "BLOCKCHAIN_ERROR",
      payload: { error, origin },
    });
};
export const handleError = (error) => {
  if (error.code) {
    if (typeof error.code === "string") {
      switch (error.code) {
        case "INVALID_ARGUMENT":
          return { message: "مقدار وارد شده معتبر نیست." };
        default:
          return { message: `خطای نامشخص از سمت سرور. کد خطا: ${error.code}` };
      }
    } else {
      switch (error.code) {
        case 4001:
          return { message: "تراکنش توسط کاربر لغو شد." };
        default:
          return { message: `خطای نامشخص از سمت سرور. کد خطا: ${error.code}` };
      }
    }
  }
};
