import * as actionTypes from "../actionTypes";
const defaultState = {
  blockchainData: {
    account: "",
    blockpay: {},
    wallet: "",
    web3: null,
  },
};
const blockchainData = (state = defaultState, actions) => {
  switch (actions.type) {
    case actionTypes.SET_WALLET_LIST:
      return {
        ...state,
        blockchainData: {
          ...state.blockchainData,
          ...actions.payload,
        },
      };
    case actionTypes.LOAD_WEB3:
      return {
        ...state,
        blockchainData: { ...state.blockchainData, web3: actions.payload },
      };
    case actionTypes.SET_WALLET:
      return {
        ...state,
        blockchainData: { ...state.blockchainData, ...actions.payload },
      };
    case actionTypes.SET_UPDATED_WALLET:
      const updatedWallet = actions.payload;
      return {
        ...state,
        blockchainData: {
          ...state.blockchainData,
          gatewayWallets: state.blockchainData.gatewayWallets.map((wallet) =>
            wallet.tokenAddress === updatedWallet.tokenAddress ? updatedWallet : wallet,
          ),
        },
      };
    case actionTypes.LOGOUT:
      return { ...state, blockchainData: defaultState };
    default:
      return state;
  }
};

export const results = (state = { results: null }, actions) => {
  switch (actions.type) {
    case actionTypes.SET_RESULTS:
      return {
        ...state,
        results: actions.payload,
      };
    case actionTypes.RESET_RESULTS:
      return {
        ...state,
        results: null,
      };
    default:
      return state;
  }
};

export default blockchainData;
