import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

// actions && methods:
import { error as errorNotif } from "react-notification-system-redux";

// Components:
import TabSelector from "./FormComponents/TabSelector";
import DropdownSelect from "./FormComponents/DropdownSelect";
import ValueInput from "./FormComponents/ValueInput";
import ShowSelectedCuerrency from "./FormComponents/ShowSelectedCuerrency";
import CurrentValueDisplay from "./FormComponents/CurrentValueDisplay";
import PairDisplay from "./FormComponents/PairDisplay";

// Assets
import toGateway from "../../assets/images/to-gateway-image.svg";
import direct from "../../assets/images/to-wallet-image.svg";
import Loading from "../Loading";

class Landing extends Component {
  static propTypes = {
    web3Utils: PropTypes.object,
    gatewayWallets: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
        name: PropTypes.string,
        tokenAddress: PropTypes.string,
        icon: PropTypes.any,
      }),
    ),
    handleLogin: PropTypes.func.isRequired,
  };
  state = {
    loading: true,
    paymentMethod: "toGateway",
    gatewayWallets: [],
    currencyType: {},
    isTransactionInProgress: false,
    transactionInfo: null,
  };
  componentDidMount() {
    const { gatewayWallets } = this.props;
    this.setState({
      gatewayWallets,
      currencyType: gatewayWallets[0],
      loading: false,
      isTransactionInProgress: false,
    });
  }
  handleCurrencyChange = (option) => {
    this.setState({ currencyType: option });
  };
  render() {
    const illustrations = { toGateway, direct };
    if (this.state.loading) {
      return <Loading />;
    } else {
      return (
        <>
          <div className="confirm-box-style user-page">
            <div className="form-divider right fixed">
              <TabSelector
                name="paymentMethod"
                handleChange={(name, value) => {
                  this.setState({ [name]: value });
                }}
                tabs={[
                  { value: "toGateway", label: "پرداخت به درگاه" },
                  { value: "direct", label: "پرداخت مستقیم" },
                ]}
              />
              <form
                id=""
                className="form-wrapper withdraw-from-gateway"
                onSubmit={(ev) => {
                  ev.preventDefault();
                }}
              >
                <DropdownSelect
                  name="currencyType"
                  label="نوع ارز"
                  id="currency-type"
                  parentClass="select-currency-type"
                  selectClass="select"
                  onChangeAction={this.handleCurrencyChange}
                  options={this.state.gatewayWallets}
                />
                <ShowSelectedCuerrency
                  {...this.state.currencyType}
                  showBalance={false}
                />
                <ValueInput
                  name="currencyValue"
                  label="مقدار"
                  type="number"
                  id="currency-value-input"
                  icon={this.state.currencyType?.name}
                  className="currency-value"
                  onChangeAction={() => {}}
                  balance={Number(this.state.currencyType?.balance)}
                  weiBalance={this.state.currencyType?.weiBalance}
                  toWei={() => {}}
                />
                <CurrentValueDisplay
                  exchangeRate={this.state.currencyType?.exchangeRate}
                />
                <PairDisplay cryptoValue={0} IRTValue={0} />
                <div className="buttons-wrapper">
                  <input
                    type="submit"
                    className="button proceed disabled"
                    value="پرداخت"
                  />
                </div>
              </form>
            </div>
          </div>
          <img
            src={illustrations[this.state.paymentMethod]}
            alt="Payment to Gateway"
            className="image-illustrate"
          />
        </>
      );
    }
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = { errorNotif };

export default connect(mapStateToProps, mapDispatchToProps)(Landing);
