import React from 'react';
import ModalOverlay from './partials/ModalOverlay'
import Loader from './partials/Loader'
import './App.css';

const Loading = ({isLoading}) => {
  return (
    <ModalOverlay >
      <Loader {...isLoading}/>
    </ModalOverlay>
  )
}

export default Loading;